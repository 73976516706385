import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const opmfForTVChart2 = createIndicator({
  displayName: '選擇權金流',
  id: 'OPMF',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10)
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker.includes('AM') ? 'TXAM-1#opmf_acc' : 'TX-1#opmf_acc'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      /*
        open: 'call',
        high: 'put',
        low: 'strong_call',
        close: 'strong_put',
        volume: 'call',
      */
      context.select_sym(1)
      const o = this.PineJS.Std.open(context)
      const h = this.PineJS.Std.high(context)
      const l = this.PineJS.Std.low(context)
      const c = this.PineJS.Std.close(context)

      // for backward compatibility
      if (o === h && c === l) {
        return [o, c, NaN, NaN] // call, put
      }

      // new format with 4 values
      return [o, h, l, c] // call, put, strong_call, strong_put
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
      { id: 'plot_2', type: 'line' },
      { id: 'plot_3', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          // Plot type is Line
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          // Plot type is Line
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#006000',
        },
        plot_2: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 3,
          trackPrice: false,
          transparency: 40,
          color: '#ff0000',
        },
        plot_3: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 3,
          trackPrice: false,
          transparency: 40,
          color: '#006000',
        },
      },

      // Precision is 0 digit, only integer
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: 'Call-總和',
        histogramBase: 0,
      },
      plot_1: {
        title: 'Put-總和',
        histogramBase: 0,
      },
      plot_2: {
        title: 'Call-主力',
        histogramBase: 0,
      },
      plot_3: {
        title: 'Put-主力',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
