import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

export const adForTVChart2 = createIndicator({
  displayName: '漲跌家數',
  id: 'AD',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10)
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol = ticker + '#advance_decline'

      context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      context.select_sym(1)

      // You can use following built-in functions in PineJS.Std object:
      // 	open, high, low, close
      //	hl2, hlc3, ohlc4
      const o = this.PineJS.Std.open(this._context) // up
      const h = this.PineJS.Std.high(this._context) //down
      const l = this.PineJS.Std.low(this._context) // unchanged
      const c = o - h // diff

      return [o, -h, l, c]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
      { id: 'plot_2', type: 'line' },
      { id: 'plot_3', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 5,
          trackPrice: false,
          transparency: 70,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 5,
          trackPrice: false,
          transparency: 70,
          color: '#007f00',
        },
        plot_2: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 100,
          color: '#333333',
        },
        plot_3: {
          linestyle: 1,
          visible: true,
          linewidth: 4,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 30,
          color: '#9900ff',
        },
      },

      // Precision is 0 digit, only integer
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: '漲家數',
        histogramBase: 0,
      },
      plot_1: {
        title: '跌家數',
        histogramBase: 0,
      },
      plot_2: {
        title: '平盤',
        histogramBase: 0,
      },
      plot_3: {
        title: '漲跌淨家數',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
