import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const opofForTVChart2 = createIndicator({
  displayName: '選擇權訂單流',
  id: 'OPOF',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10)
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#opof'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      /* 
        open: 'call_filled_count_diff',
        high: 'put_filled_count_diff',
        low: 'cp_diff',
        close: 'cp_diff',
        volume: 'cp_diff',
      */
      context.select_sym(1)
      let c = this.PineJS.Std.close(context) // OPOF
      if (c === 0) {
        c = NaN
      }
      if (c > 0) {
        return [c, NaN]
      }

      return [NaN, c]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 5,
          trackPrice: false,
          transparency: 30,
          color: '#ff9900',
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          // Plot type is Line
          plottype: 5,
          trackPrice: false,
          transparency: 30,
          color: '#9933ff',
        },
      },

      // Precision is 0 digit, only integer
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: 'OPOF',
        histogramBase: 0,
      },
      plot_1: {
        title: 'OPOF',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
