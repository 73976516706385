import { proxy } from 'valtio'

/** 指標傳參數 */
export const eurexIndicatorsValueStorage = proxy({
  // Volume
  highestVolume5: NaN as number,
  highestVolume20: NaN as number,
  highestVolume60: NaN as number,
  lowestVolume5: NaN as number,
  lowestVolume20: NaN as number,
  lowestVolume60: NaN as number,
  averageVolume5: NaN as number,
  averageVolume20: NaN as number,
  averageVolume60: NaN as number,

  // OHLC
  dayOpen: NaN as number,
  dayHigh: NaN as number,
  dayLow: NaN as number,
  dayClose: NaN as number,
  dayVolume: NaN as number,

  highestHigh5: NaN as number,
  highestHigh20: NaN as number,
  highestHigh60: NaN as number,
  lowestLow5: NaN as number,
  lowestLow20: NaN as number,
  lowestLow60: NaN as number,
  rsv5: NaN as number,
  rsv20: NaN as number,
  rsv60: NaN as number,

  // Mov avg
  ma5: NaN as number,
  ma20: NaN as number,
  ma60: NaN as number,
  ma120: NaN as number,

  // Mov avg slope
  slope5: NaN as number,
  slope20: NaN as number,
  slope60: NaN as number,
  slope120: NaN as number,

  // bias
  bias5: NaN as number,
  bias20: NaN as number,
  bias60: NaN as number,
  bias120: NaN as number,

  // offset value of mov avg
  offsetValue5: NaN as number,
  offsetValue20: NaN as number,
  offsetValue60: NaN as number,
  offsetValue120: NaN as number,

  // power
  powerTrend: NaN as number,
  powerMacd: NaN as number,
  powerDMI: NaN as number,

  // amp
  currentAmp: NaN as number,
  averageAmp05: NaN as number,
  averageAmp20: NaN as number,
  averageAmp60: NaN as number,
  averageAmp120: NaN as number,

  // interval
  internalType: '' as '週' | '日' | '小時' | '分鐘',
})

export const resetIndicatorsValue = () => {
  eurexIndicatorsValueStorage.highestVolume5 = NaN
  eurexIndicatorsValueStorage.lowestVolume5 = NaN
  eurexIndicatorsValueStorage.highestVolume20 = NaN
  eurexIndicatorsValueStorage.lowestVolume20 = NaN
  eurexIndicatorsValueStorage.highestVolume60 = NaN
  eurexIndicatorsValueStorage.lowestVolume60 = NaN
  eurexIndicatorsValueStorage.averageVolume5 = NaN
  eurexIndicatorsValueStorage.averageVolume20 = NaN
  eurexIndicatorsValueStorage.averageVolume60 = NaN

  eurexIndicatorsValueStorage.dayOpen = NaN
  eurexIndicatorsValueStorage.dayHigh = NaN
  eurexIndicatorsValueStorage.dayLow = NaN
  eurexIndicatorsValueStorage.dayClose = NaN
  eurexIndicatorsValueStorage.dayVolume = NaN

  eurexIndicatorsValueStorage.highestHigh60 = NaN
  eurexIndicatorsValueStorage.highestHigh20 = NaN
  eurexIndicatorsValueStorage.highestHigh5 = NaN
  eurexIndicatorsValueStorage.lowestLow60 = NaN
  eurexIndicatorsValueStorage.lowestLow20 = NaN
  eurexIndicatorsValueStorage.lowestLow5 = NaN
  eurexIndicatorsValueStorage.rsv60 = NaN
  eurexIndicatorsValueStorage.rsv20 = NaN
  eurexIndicatorsValueStorage.rsv5 = NaN

  eurexIndicatorsValueStorage.ma5 = NaN
  eurexIndicatorsValueStorage.ma20 = NaN
  eurexIndicatorsValueStorage.ma60 = NaN
  eurexIndicatorsValueStorage.ma120 = NaN
  eurexIndicatorsValueStorage.slope5 = NaN
  eurexIndicatorsValueStorage.slope20 = NaN
  eurexIndicatorsValueStorage.slope60 = NaN
  eurexIndicatorsValueStorage.slope120 = NaN
  eurexIndicatorsValueStorage.bias5 = NaN
  eurexIndicatorsValueStorage.bias20 = NaN
  eurexIndicatorsValueStorage.bias60 = NaN
  eurexIndicatorsValueStorage.bias120 = NaN
  eurexIndicatorsValueStorage.offsetValue5 = NaN
  eurexIndicatorsValueStorage.offsetValue20 = NaN
  eurexIndicatorsValueStorage.offsetValue60 = NaN
  eurexIndicatorsValueStorage.offsetValue120 = NaN
}
