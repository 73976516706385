import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const ivForTVChart2 = createIndicator({
  displayName: '隱含波動率',
  id: 'IV',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10)
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#iv'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      context.select_sym(1)
      const o = this.PineJS.Std.open(context) // time value
      const h = this.PineJS.Std.high(context) // synthetic futures
      let itm = this.PineJS.Std.low(context) //  ITM
      let otm = this.PineJS.Std.close(context) // OTM
      let atm = this.PineJS.Std.volume(context) // ATM

      if (itm === 0) {
        itm = NaN
      }
      if (otm === 0) {
        otm = NaN
      }

      if (atm === 0) {
        atm = NaN
      }

      return [atm, otm, itm] //
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
      { id: 'plot_2', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          // Plot type is Line
          plottype: 9,
          trackPrice: false,
          transparency: 50,
          color: '#6600cc',
        },
        plot_1: {
          linestyle: 0,
          visible: false,
          linewidth: 1,
          // Plot type is Line
          plottype: 9,
          trackPrice: false,
          transparency: 60,
          color: '#b366ff',
        },
        plot_2: {
          linestyle: 0,
          visible: false,
          linewidth: 1,
          plottype: 9,
          trackPrice: false,
          transparency: 70,
          color: '#4d0099',
        },
      },

      // Precision is 0 digit, only integer
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: '隱含波動率-價平',
        histogramBase: 0,
      },
      plot_1: {
        title: '隱含波動率-價外一檔',
        histogramBase: 0,
      },
      plot_2: {
        title: '隱含波動率-價內一檔',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
