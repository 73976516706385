import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const bs2ForTVChart2 = createIndicator({
  displayName: '內外盤比差(%)',
  id: 'BS2',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10) ||
      (symbol.length == 4 && parseInt(symbol) > 0)
    )
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol = ticker + '#bs_indicator_acc'
      context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      /*
        open: 'net_buy',
        high: 'net_strong_buy',
        low: 'net_weak_buy',
        close: 'buy_volume',
        volume: 'sell_volume',
      */
      context.select_sym(1)
      const c = this.PineJS.Std.close(this._context) // net buy volume
      const v = this.PineJS.Std.volume(this._context) // net sell volumne
      const bs_ratio = ((c - v) / (c + v)) * 100
      return [v, c, bs_ratio]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
      { id: 'plot_2', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: false,
          linewidth: 2,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 30,
          color: '#00ff00',
        },
        plot_1: {
          linestyle: 0,
          visible: false,
          linewidth: 2,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 30,
          color: '#ff0000',
        },
        plot_2: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          // Plot type is Line
          plottype: 4,
          trackPrice: false,
          transparency: 20,
          color: '#8b8b8b',
        },
      },

      // Precision is 0 digit, only integer
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: '內盤量',
        histogramBase: 0,
      },
      plot_1: {
        title: '外盤量',
        histogramBase: 0,
      },
      plot_2: {
        title: '內外盤比差(%)',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
