import { useIndicatorStore } from '~/store/useIndicatorStore'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const dc = createIndicator({
  displayName: '階梯線',
  id: 'dc',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const open = this.ohlc.open
      const high = this.ohlc.high
      const low = this.ohlc.low
      const close = this.ohlc.close

      const open_array = this.ohlc.openArray
      const high_array = this.ohlc.highArray
      const low_array = this.ohlc.lowArray
      const close_array = this.ohlc.closeArray

      const length = this._input(0)
      const r = this.PineJS.Std.low(this._context)
      const o = this._context.new_var(r)
      const dn = this.PineJS.Std.lowest(o, length, this._context)
      const a = this.PineJS.Std.high(this._context)
      const l = this._context.new_var(a)
      const up = this.PineJS.Std.highest(l, length, this._context)

      const up_line = this._context.new_var()
      const dn_line = this._context.new_var()
      const plot_flag = this._context.new_var()

      dn_line.get(1)
      up_line.get(1)
      high_array.get(1)
      low_array.get(1)
      plot_flag.get(0)
      plot_flag.get(1)

      if (high_array.get(0) >= up) {
        plot_flag.set(1)
      }
      if (low_array.get(0) <= dn) {
        plot_flag.set(-1)
      }

      if (plot_flag.get(0) === 1) {
        up_line.set(NaN)
        dn_line.set(dn)
      } else if (plot_flag.get(0) === -1) {
        up_line.set(up)
        dn_line.set(NaN)
      }

      return [dn_line.get(0), up_line.get(0)]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 7,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#00aa00',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 7,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#aa0000',
        },
      },
      precision: 4,
      inputs: {
        in_0: 60,
   
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'Lower',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'Upper',
        histogramBase: 0,
        joinPoints: !1,
      },
    },

    is_price_study: !0,
    inputs: [
      {
        id: 'in_0',
        name: '階梯length',
        defval: 60,
        type: 'integer',
        min: 1,
        max: 2e3,
      },

    ],
    scriptIdPart: '',
  },
})
