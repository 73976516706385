import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const tiForTVChart2 = createIndicator({
  id: 'TI',
  displayName: '掛單能量',
  enabledOn(symbol, data, channel) {
    return (
      (data?.type === channel.tw_futures && symbol.includes('-')) ||
      (symbol.includes('TX') && symbol.length == 10)
    )
  },
  constructorScope: {
    init(context, inputcallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#trade_info'
      context.new_sym(symbol, this.PineJS.Std.period(context))
    },
    main(context, inputcallback) {
      /*
        open: 'unfilled_lot_diff',
        high: 'unfilled_count_diff',
        low: 'filled_count_diff',
        close: 'filled_count_diff',
        volume: 'filled_count_diff',
      */
      context.select_sym(1)
      const o = this.PineJS.Std.open(context)
      const h = this.PineJS.Std.high(context)
      const l = this.PineJS.Std.low(context)
      return [o, l, h]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
      { id: 'plot_2', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          // Plot type is Area
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#0000ff',
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          // Plot type is Area
          plottype: 4,
          trackPrice: false,
          transparency: 60,
          color: '#007f00',
        },
        plot_2: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 30,
          color: '#ff0000',
        },
      },

      // Precision is 0 digit, only integer
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: '大戶掛單',
        histogramBase: 0,
      },
      plot_1: {
        title: '散戶成交',
        histogramBase: 0,
      },
      plot_2: {
        title: '市場掛單',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
